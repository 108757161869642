import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Home from "../pages/home/HomeComponent";
import Splash from "../pages/splash/Splash";
import Contact from "../pages/contact/ContactComponent";
import Projects from "../pages/about/Projects.js";
import Services from "../pages/services/services";
import Catlogue from "../pages/catlogue/catlogue.js";
import { settings } from "../portfolio.js";
import Error404 from "../pages/errors/error404/Error";
import Work from "../pages/work/work";
export default class Main extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>
          <Route
            path="/"
            exact
            render={(props) => <Home {...props} theme={this.props.theme} />}
          />
          <Route
            path="/home"
            render={(props) => <Home {...props} theme={this.props.theme} />}
          />
          <Route
            path="/services"
            render={(props) => <Services {...props} theme={this.props.theme} />}
          />
         
          <Route
            path="/contact"
            render={(props) => <Contact {...props} theme={this.props.theme} />}
          />
 
          <Route
            path="/works"
            render={(props) => <Work {...props} theme={this.props.theme} />}
          />

          {settings.isSplash && (
            <Route
              path="/splash"
              render={(props) => <Splash {...props} theme={this.props.theme} />}
            />
          )}

          <Route
            path="/about"
            render={(props) => <Projects {...props} theme={this.props.theme} />}
          />
           <Route
            path="/catlogue"
            render={(props) => <Catlogue {...props} theme={this.props.theme} />}
          />
          <Route
            path="*"
            render={(props) => <Error404 {...props} theme={this.props.theme} />}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

import React from "react";
import "./Greeting.css";
import { greeting } from "../../portfolio";
import { Fade } from "react-reveal";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import TypingAnimation from "../../components/TypingAnimation";
import LoopingVideo from "../../components/greetVideo/video";
import Button from "../../components/button/Button";
export default function Greeting(props) {
  const theme = props.theme;
  return (
    <Fade bottom duration={2000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
          <h1 className="greeting-text" style={{ color: theme.text }}>
            {greeting.title}
          </h1>  
              {/* <TypingAnimation /> */}
              {/* {greeting.nickname && (
                <h2 className="greeting-nickname" style={{ color: theme.text }}>
                  ( {greeting.nickname} )
                </h2>
              )} */}
              <p
                className="greeting-text-p subTitle"
                style={{ color: theme.secondaryText }}
              >
                {greeting.subTitle}
              </p>
              <div className="portfolio-repo-btn-div">
                <Button
                  text="Our Recent Works"
                  newTab={true}
                  href={"/works"}
                  theme={theme}
                  className="portfolio-repo-btn"
                />
              </div> 
            </div>
          </div>
          <div className="greeting-image-div">
            <LoopingVideo />
          </div>
        </div>

        <SocialMedia theme={theme} />     
      </div>
   
    </Fade>
  );
}

import React, { Component } from "react";
import Header from "../../components/header/Header.js";
import TopButton from "../../components/topButton/TopButton.js";
import { Fade } from "react-reveal";
import "./Projects.css";
import { contactPageData } from "../../portfolio";
import Meet from "../../containers/request/request.js";
import Footer from "../../components/footer/Footer.js";
const ContactData = contactPageData.contactSection;
const words = "About Us".split(" ");
const agile = "Agile Frameworks".split(" ");
const sanjana = "Technology Integration".split(" ");
class About extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade left duration={2000} distance="40px">
            <div className="contact-heading-div">
              
              <div className="contact-heading-text-div">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text }}
                >
                  {words.map((word, index) => (
                    <span key={index} style={{ borderBottom: index ===1 ? "4px solid #fca326" : "inherit"}}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
                <p
                    className="contact-header-detail-text subTitle"
                    style={{ color: theme.secondaryText , fontSize: "15px" , textAlign : "justify"}}
                 
                 >
                  {ContactData["description"]}
                </p>
              </div>
            </div>
          </Fade>
          <Fade right duration={2000} distance="40px" >
            <div className="contact-heading-div" style={{marginTop: "2%"}}>
            <div className="contact-heading-img-div">
                <img
                  alt=""
                  src={require("../../assests/images/about.png")}
                  
                ></img>
              </div>
              <div className="contact-heading-text-div">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text ,}}
                >
                  {sanjana.map((word, index) => (
                    <span key={index} style={{ borderBottom: index ===1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
                 
                <p
                  className="greeting-text-p subTitle"
                  style={{ color: theme.secondaryText , fontSize: "14px" , textAlign : "justify"}}
               >
        We take pride in our proficiency with a comprehensive suite of cutting-edge stacks, ensuring that your projects benefit from the latest advancements in the tech world. Our expertise spans across a diverse range of technologies, including Node.js for robust server-side development, React.js for crafting dynamic and responsive user interfaces, and MongoDB for flexible and scalable data management. We prioritize code quality and maintainability through the integration of TypeScript, and our commitment extends to leveraging cloud solutions with AWS.         
                </p>
              </div>
            </div>
          </Fade>
          <div>
          {/* <Meet theme={this.props.theme} style={{marginTop: "9%"}} /> */}
          </div>
          <Fade bottom duration={2000} distance="40px">
            <div className="contact-heading-div">
              
              <div className="contact-heading-text-div">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text }}
                >
                  {agile.map((word, index) => (
                    <span key={index} style={{ borderBottom: index ===1 ? "4px solid #fca326" : "inherit"}}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
                <p
                    className="contact-header-detail-text subTitle"
                    style={{ color: theme.secondaryText , fontSize: "15px" , textAlign : "justify"}}
                 >
                  Embracing Agile methodologies, we prioritize flexibility, collaboration, and iterative progress in our approach. Our Agile framework fosters a dynamic and responsive development environment, allowing for the swift creation of applications. This commitment to agility ensures that our development processes are adaptive, collaborative, and capable of delivering high-quality solutions efficiently.
                </p>
              </div>
            </div>
          </Fade>
         
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default About;

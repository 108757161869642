import React, { Component } from 'react'
import { Fade } from 'react-reveal';
import Button from "../../components/button/Button";

export default class request extends Component {
    render() {
        const theme = this.props.theme;
        return (
          <div className="main" id="certs" style={{background: "rgb(230 222 211)"}}>
            <div className="certs-header-div">
              <Fade bottom duration1={2000} distance="20px">
                {/* <h1 className="certs-header" style={{ color: theme.text }}>
                  Our Beautifully <span className="brushed-underline">Crafted Works</span>
                </h1> */}
                <h1 className='certs-header' style={{ color: theme.text , fontSize: "27px"}}>
                    Schedule A Meeting With Us!
                </h1>
                <h3 className='certs-header' style={{ color: theme.text, marginLeft: "15px", fontSize: "20px"}}> Let's Discuss your idea and build something amazing together.</h3>
                <div className="portfolio-repo-btn-div">
                <Button
                  text="Get In Touch"
                  newTab={true}
                  href={"https://api.whatsapp.com/send?phone=9644727557&text=Hello"}
                  theme={theme}
                  className="certs-header"
                />
              </div> 
              </Fade>
            </div>
          </div>
        );
      }
   }


const settings = {
  isSplash: false,
};

const seo = {
  title: "DigitalEase Solutions - Your Gateway to Seamless Digitalization",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
};

//Home Page
const greeting = {
  title: "DigitalEase Solutions",
  logo_name: "DigitalEase",
  logo_path: "cbse_logo.png",
  subTitle:
    "  Empowering Sustainable Growth Through Seamless Digitalization Excellence.🌐🚀 ",
  resumeLink: "https://drive.google.com/",
  githubProfile: "https://github.com/sanjanas6",
  portfolio_repository: "/contact",
};

const socialMediaLinks = [
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/company/digitalease-solutions",
    fontAwesomeIcon: "fa-linkedin-in",
    backgroundColor: "#0077B5",
  },
  {
    name: "Gmail",
    link: "mailto:info@digotalease.co.in",
    fontAwesomeIcon: "fa-google",
    backgroundColor: "#D14836",
  },
  {
    name: "Instagram",
    link:
      "https://www.instagram.com/digitalease_solution?igshid=OGQ5ZDc2ODk2ZA==",
    fontAwesomeIcon: "fa-instagram",
    backgroundColor: "#E4405F",
  },
  {
    name: "Whatsapp",
    link: "https://api.whatsapp.com/send?phone=9644727557&text=Hello",
    fontAwesomeIcon: "fa-whatsapp",
    backgroundColor: "#181717",
  },
];

const skills = {
  data: [
    {
      title: "Website Development",
      fileName: "web.png",
      skills: [
        "We merge creativity and functionality to craft captivating online experiences. Elevate your digital presence with us – where innovation meets seamless functionality.",
        "⚡ Website Design and Development",
        "⚡ Web Maintenance",
        "⚡ Web Hosting & Domain",
        "⚡ Custom Web Application",
      ],
    },
    {
      title: "E-commerce Solution",
      fileName: "Ecom.png",
      skills: [
        "Your destination for cutting-edge e-commerce solutions. We blend creativity and functionality to craft engaging online shopping experiences.",
        "⚡ Payment Gateway Integration",
        "⚡ Customer Loyalty Program - Gift Card & Vouchers",
        "⚡ Affiliate Marketing Integration",
        "⚡ Shopping Cart Integration",
        "⚡ Inventory Management",
      ],
    },
    {
      title: "Search Engine Optimization (SEO)",
      fileName: "SEO.png",
      skills: [
        "We specialize in optimizing your digital presence to reach new heights. Unlock your website's potential with us – where innovation meets effective SEO.",
        "⚡ On-Page / Landing Page Optimization",
        "⚡ Keyword Research & Analysis",
        "⚡ Local Business Listings & Consultancy",
        "⚡ SEO Content Writing",
        "⚡ SEO Monitoring & Reporting",
      ],
    },
    {
      title: "Digital Marketing",
      fileName: "Digital.png",
      skills: [
        "We combine strategy and creativity to boost your online presence. Transform your brand with us – where innovation meets impactful marketing.",
        "⚡ Content Marketing",
        "⚡ Social Media Marketing",
        "⚡ Email Marketing",
        "⚡ Affiliate Marketing",
        "⚡ Facebook Ads",
      ],
    },
    {
      title: "Graphic Designing",
      fileName: "GD.png",
      skills: [
        "We turn ideas into visuals that resonate. Elevate your brand aesthetics with us – where creativity meets impactful design",
        "⚡ Logo Design & Branding",
        "⚡ Illustration",
        "⚡ Social Media Graphics",
        "⚡ Advertising Design",
        "⚡ Email Newsletter Design & Presentation",
      ],
    },
    {
      title: "Content Writing",
      fileName: "Content.png",
      skills: [
        "We craft words that captivate and communicate your story. Transform your narrative with us – where precision meets impactful storytelling.",
        "⚡ Blog Post",
        "⚡ Content Strategy & Marketing",
        "⚡ Content Curation",
        "⚡ Content Editing & Optimization",
        "⚡ Email Newsletter Design",
      ],
    },
  ],
};

const projects = {
  projects: [
    {
      title: "E-Commerce",
      subtitle: "- Online Marketplace",
      logo_path: "Bookzy.png",
      hover: "p1.png",
      certificate_link: "https://www.bookzy.in",
      color_code: "#CCCCCC66",
    },
    {
      title: "Pustakaalay",
      subtitle: "- Library Management System",
      logo_path: "pustak.png",
      hover: "p2.png",
      certificate_link: "https://user.pustakaalay.in/",
      color_code: "#CCCCCC66",
    },

    {
      title: "InventoSync",
      subtitle: "- Inventory Management System",
      logo_path: "inventory.png",
      hover: "p3.png",
      certificate_link: "https://inventory.bookzy.in",
      color_code: "#CCCCCC66",
    },
    {
      title: "Daanshiksha",
      subtitle: "- Informative Website",
      logo_path: "daan.png",
      hover: "p4.png",
      certificate_link: "https://daanshiksha.pustakaalay.in/",
      color_code: "#CCCCCC66",
    },
    {
      title: "POS",
      subtitle: "- Store Management Application",
      logo_path: "pos.png",
      hover: "p9.png",
      certificate_link: "https://www.shridantay.com",
      color_code: "#CCCCCC66",
    },
    {
      title: "TechBlog",
      subtitle: "- Dynamic Blogging Website",
      logo_path: "blog.png",
      hover: "p5.png",
      certificate_link:
        "https://6390c8efdfcf234984ab4925--static-blog-site.netlify.app/",
      color_code: "#CCCCCC66",
    },
    // {
    //   title: "Manufacturer/Trader/Wholesaler",
    //   subtitle: "- Informative Website",
    //   logo_path: "shri.png",
    //   hover: "p7.png",
    //   certificate_link: "https://www.shridantay.com",
    //   color_code: "#CCCCCC66",
    // },
    {
      title: "Portfolio",
      subtitle: "- Custom Portfolio Website",
      logo_path: "portfolio.png",
      hover: "p6.png",
      certificate_link:
        "https://6390c8efdfcf234984ab4925--static-blog-site.netlify.app/",
      color_code: "#CCCCCC66",
    },
    {
      title: "Spice Import Export",
      subtitle: "- Informative Website",
      logo_path: "ksj.png",
      hover: "p7.png",
      certificate_link: "https://www.ksjoverseas.com",
      color_code: "#CCCCCC66",
    },
    {
      title: "Global Trader",
      subtitle: "- Informative Website",
      logo_path: "shri (2).png",
      hover: "p8.png",
      certificate_link: "https://www.shridantay.com",
      color_code: "#CCCCCC66",
    },
    
  ],
};

const experience = {
  header_image_path: "experience.svg",
  sections: [
    {
      title: "How do I get started with your services?",
      work: true,
      experiences: [
        {
          description:
            "Getting started is easy! Simply visit our Contact Us page, Drop us a message,",
          color: "#9b1578",
        },
      ],
    },
    {
      title: " Can you provide examples of your previous work?",
      experiences: [
        {
          description:
            "Certainly! Explore our Portfolio to see a showcase of our projects across various services.",
          color: "#4285F4",
        },
      ],
    },
    {
      title: " How long does it take to complete a project?",
      experiences: [
        {
          description:
            " The timeline varies based on the scope and complexity of the project. We'll provide a detailed timeline during the initial consultation.",
          color: "#4285F4",
        },
      ],
    },
    {
      title: "What is the cost of your services?",
      experiences: [
        {
          description:
            "Our pricing is tailored to the specific needs of each project. For a detailed quote, please contact us with information about your requirements.",
          color: "#4285F4",
        },
      ],
    },
    {
      title: "Do you offer ongoing support after the project is completed?",
      experiences: [
        {
          description:
            "Yes, we offer post-launch support and maintenance services. Our goal is to ensure your continued success in the digital space.",
          color: "#4285F4",
        },
      ],
    },
    {
      title: "Can I make changes to my project after it has started?",
      experiences: [
        {
          description:
            "We understand that requirements may evolve. Changes are accommodated based on the project timeline and scope. ",
          color: "#4285F4",
        },
      ],
    },
    {
      title: "How can I stay updated on your latest news and offerings?",
      experiences: [
        {
          description:
            "Stay connected with us through our Blog and follow us on social media. ",
          color: "#4285F4",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "About Us",
};

const contactPageData = {
  contactSection: {
    title: "Contact Us",
    description:
  "Digitalease: Your one-stop digital partner. We excel in website development, e-commerce solutions, SEO, digital marketing, graphic design, and content writing. Elevate your brand's online presence with our comprehensive expertise.Digitalease is your go-to destination for cutting-edge development projects and tech solutions that elevate your business. It's a platform born out of a deep-rooted understanding of the intricacies of technology and a dedication to delivering top-notch solutions for your digital needs.", 
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    locality: "Nimach",
    country: "IN",
    region: "Madhya Pradesh",
    postalCode: "458441",
    avatar_image_path: "address_image.svg",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  projects,
  experience,
  projectsHeader,
  contactPageData,
};

import React, { useEffect } from 'react';

export default function Catalogue() {
  useEffect(() => {
    window.location.href = 'https://online.flippingbook.com/view/360565617/';
  }, []);

  return (
    <div>
    </div>
  );
}

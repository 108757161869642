import React from "react";
import "./Skills.css";
import SkillSection from "./SkillSection";
import TypingAnimation from "./TypingAnimation";
import { Fade } from "react-reveal";
const words = "Our Expertise".split(" ");
export default function Skills(props) {
  const theme = props.theme;
  return (
    <div className="main" id="skills">
      <div className="skills-header-div">
        <Fade bottom duration={2000} distance="20px">
          <h1 className="skills-header" style={{ color: theme.text }}>
            {words.map((word, index) => (
              <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit"}}>
                {word}{' '}
              </span>
            ))}
              <TypingAnimation style={{fontSize: "1px"}}/>
          </h1>
        
        </Fade>
      </div>
      <SkillSection theme={theme} />
    </div>
  );
}
